export default {
    部署コード: '部署コード',
    部署名: '部署名',
    フリガナ: 'フリガナ',
    略称: '略称',
    グループ略称: '略称',
    従業員コード: '従業員コード',
    グループ従業員コード: '従業員コード',
    責任者氏名: '責任者氏名',
    グループ責任者名: '責任者氏名',
    下位コード: '下位コード',
    グループ名: 'グループ名',
    グループフリガナ: 'フリガナ',
    チェックがある役職のみ表示: 'チェックがある役職のみ表示'
};
