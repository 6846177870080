import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from 'dayjs';
import vClickOutside from 'click-outside-vue3';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueCookies from 'vue3-cookies';
import '@/assets/css/fontawesome.min.css';

import AnsInput from '@/components/ansInput';
import AnsDatePicker from '@/components/ansDatePicker';
import AnsTimePicker from '@/components/ansTimePicker';
import AnsSelect from '@/components/ansSelect';
import AnsCheckbox from '@/components/ansCheckbox';
import AnsRadioButton from '@/components/ansRadioButton';
import AnsFile from '@/components/ansFile';
import AnsSwitch from '@/components/ansSwitch';
import AnsButton from '@/components/ansButton';
import AnsInputSearch from '@/components/ansInputSearch';
import AnsList from '@/components/ansList';
import AnsTable from '@/components/ansTable';
import AnsPanelHeader from '@/components/ansPanelHeader';
import AnsPanel from '@/components/ansPanel';
import AnsZipCode from '@/components/ansZipCode';
import AnsPhone from '@/components/ansPhone';
import AnsEmail from '@/components/ansEmail';
import AnsPaging from '@/components/ansPaging';
import CompanyName from '@/components/companyName';
import FilterButton from '@/components/filterButton';
import VueSignaturePad from 'vue-signature-pad';
import AnsSelectSearch from '@/components/ansSelectSearch';

const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app.config.productionTip = false;
app.use(vClickOutside);
app.use(BootstrapVue3);
app.use(VueCookies, {
    expireTimes: '30d',
    path: '/'
});
app.use(store);
app.use(router);
app.use(VueSignaturePad);

app.component('AnsInput', AnsInput);
app.component('AnsDatePicker', AnsDatePicker);
app.component('AnsTimePicker', AnsTimePicker);
app.component('AnsSelect', AnsSelect);
app.component('AnsCheckbox', AnsCheckbox);
app.component('AnsRadioButton', AnsRadioButton);
app.component('AnsFile', AnsFile);
app.component('AnsSwitch', AnsSwitch);
app.component('AnsButton', AnsButton);
app.component('AnsInputSearch', AnsInputSearch);
app.component('AnsList', AnsList);
app.component('AnsTable', AnsTable);
app.component('AnsPanelHeader', AnsPanelHeader);
app.component('AnsPanel', AnsPanel);
app.component('AnsZipCode', AnsZipCode);
app.component('AnsPhone', AnsPhone);
app.component('AnsEmail', AnsEmail);
app.component('AnsPaging', AnsPaging);
app.component('CompanyName', CompanyName);
app.component('FilterButton', FilterButton);
app.component('AnsSelectSearch', AnsSelectSearch);

app.mount('#app');
