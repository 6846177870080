export default {
    規則種別: '規則種別',
    規則名称: '規則名称',
    シフト名称: 'シフト名称',
    表示年月: '表示年月',
    みなし残業: 'みなし残業',
    みなし深夜: 'みなし深夜',
    年休パターン: '年休パターン',
    週所定労働日数: '週所定労働日数',
    変更日: '変更日',
    業務の種類: '業務の種類',
    権限の種類: '権限の種類',
    設定内容: '設定内容'
};
