export default {
    // Error message
    E001: 'このフィールドは必須です。',
    E002: 'メールアドレスのを正しい形式で入力してください',
    E003: '入力されたパスワードが同一ではありません',
    E004: '1つ以上選択してください',
    E005: '並び順を変更する行を選択してください',
    E006: 'このフィールドは必須です。',
    E007: 'データ表示時点より古い付与日付が存在しません',
    E008: '許容文字数を超えています',
    E009: '入力されたパスワードが現在のパスワードと同一です',
    E010: 'を選択してください',
    E011: '一括設定を適用する従業員を選択して下さい',
    E012: '電話番号を正しい形式で入力してください',
    E013: '郵便番号を正しい形式で入力してください',
    E014: 'カタカナ文字のみ使用できます',
    E015: '一括条件を指定してください',
    E016: '月指定条件を選択してください',
    E017: '日付指定条件を選択してください',
    E018: '曜日指定条件を選択してください',
    E019: '終了時刻＜開始時刻となっています。正しい値を入力してください。',
    E020: '所定開始時刻をまたぐ設定はできません。正しい値を入力してください。',
    E021: '削除する申請書が選択されていません',
    E022: '入力する行を1列以上選択してください',
    E023: '正しい形式で入力してください',
    E024: '出勤時間と退勤時間を確認して下さい',
    E025: '私用外出時間を確認して下さい',
    E026: '出勤時間を確認して下さい',
    E027: '退勤時間を確認して下さい',
    E028: '退勤修正か私用外出時間を入力して下さい',
    E029: '出勤修正か私用外出時間を入力して下さい',
    E030: '出勤修正か退勤修正か私用外出時間を入力して下さい',
    E031: '有休残が足りません',
    E032: '事由区分が重複しています',
    E033: '一年で取得できる時間休の時間を超えています',
    E034: '時間休時間を入力してください',
    E035: '時休の事由フラグが選択されていません',
    E036: '時間休は時間単位で取得してください',
    E037: '打刻が完了していない従業員は選択出来ません',
    E038: '開始時刻より後の時刻を入力してください',
    E039: '開始日付以降の日付を入力してください',
    E040: '出力処理がタイムアウトになりました',
    E042: '{0}まで、シフトが確定されていないデータがあるため<br/>実行できません',
    E043: '指定日付は既に確定済みです',
    E044: '設定のない日付がある為、シフト確定できません',
    E045: '保存されていない編集データがある為、シフト確定できません',
    E046: 'シフト確定されている日を入力してください({0}以前)',
    E047: '有休残が足りません',
    E048: 'フィルタ名を入力してください',
    E049: '条件を入力してください',
    E050: '開始年月以降の年月を入力してください',
    E051: '承認者を１以上選択してください',
    E052: 'アラート方法を選択してください',
    E053: 'ダウンロードの条件を選択してください',
    E054: 'インポートの条件を選択してください',
    E055: '承認の前に申請が必要です',
    E056: '休日出勤申請を検索ボタンより選択して下さい',
    E057: '残業申請を検索ボタンより選択して下さい',
    E058: '権限がありません',
    E059: '勤怠管理の対象者ではありません',
    E060: '対象者がいません',
    E061: '休日出勤申請を検索ボタンより選択して下さい',
    E062: '有効な「起算月」・「週起算」が設定されていない為、<br/>強制終了いたします。<br/>システム設定画面にて登録を行ってください。',
    E063: '確定月のデータが含まれています',
    E064: '入力された認証コードは正しくありません',
    // Confirm message
    C001: '本日の業務は終了でよろしいですか？',
    C002: '休憩開始を打刻しますか？',
    C003: '休憩終了を打刻しますか？',
    C004: '外出開始を打刻しますか？',
    C005: '外出戻りを打刻しますか？',
    C006: '登録してもよろしいですか？',
    C007: '選択している会社を削除しますか？',
    C008: '削除するとデータの復元ができなくなりますが、<br/>よろしいですか？',
    C009: '通知を削除しますか？',
    C010: '既読にしますか？',
    C011: '選択しているフィルターを削除しますか？',
    C012: '画面を初期化して新しいフィルターの入力を開始しますか？',
    C013: '画面を初期化して新しい事業所の入力を開始しますか？',
    C014: '選択している事業所を削除しますか？',
    C015: '入力データを登録しますか？',
    C016: '画面を初期化して新しい事由区分の入力を開始しますか？',
    C017: '選択している事由区分を削除しますか？',
    C018: '初期化してもよろしいですか？',
    C019: '申請を承認しますか？',
    C020: '申請を否認しますか？',
    C021: '申請を差戻しますか？',
    C022: '画面を初期化して新しい職種の入力を開始しますか？',
    C023: '選択している職種を削除しますか？',
    C024: '削除するとデータの復元ができなくなりますが、<br/>よろしいですか？',
    C025: '画面を初期化して新しい部署の入力を開始しますか？',
    C026: '画面を初期化して新しいグループの入力を開始しますか？',
    C027: '選択している部署を削除しますか？',
    C028: '削除するとデータの復元ができなくなりますが、<br/>よろしいですか？',
    C029: '選択している下位グループを削除しますか？',
    C030: '削除するとデータの復元ができなくなりますが、<br/>よろしいですか？',
    C031: '勤務表を確定してよろしいですか？',
    C032: '画面を初期化して新しい36協定の設定の入力を開始しますか？',
    C033: '選択している36協定の設定を削除しますか？',
    C034: '新しいパスワードを発行しますか？',
    C035: '選択している従業員にパスワード通知メールを送信しますか？',
    C036: 'カレンダ設定をすべて削除しますか？',
    C037: '事業所マスタをインポートしますか？',
    C038: '組織マスタをインポートしますか？',
    C039: '職種マスタをインポートしますか？',
    C040: '役職マスタをインポートしますか？',
    C041: '従業員マスタをインポートしますか？',
    C042: '事由区分マスタをインポートしますか？',
    C043: '同一データに対して上書きを行いますがよろしいですか？',
    C044: '選択した休日出勤申請を削除してよろしいですか？',
    C045: '申請しますか？',
    C046: '申請書を削除しますか？',
    C047: '工数登録を削除しますか？',
    C048: '画面を初期化して新しい勤務日パターンの入力を開始しますか？',
    C049: '選択している勤務日パターンを削除しますか？',
    C050: '保存してもよろしいですか？',
    C051: 'カレンダー設定をすべて削除しますか？',
    C052: 'すべてのシフト申請を承認しますか？',
    C053: 'このまま保存しますか？',
    C054: 'シフト設定を保存しますか？',
    C055: 'シフト確定をした場合、その日までのシフト勤務設定の変更は<br/>できなくなりますが、よろしいですか？',
    C056: 'シフト取消を実行します。よろしいですか？',
    C057: '従業員別シフト表を作成しますか？',
    C058: '月間シフト表を作成しますか？',
    C059: '登録データを削除しますか？',
    C060: 'カレンダー設定を全て削除しますか？',
    C061: '削除しますか？',
    C062: '削除するとデータの復元ができなくなりますが、<br/>よろしいですか？',
    C063: 'から勤務実績データをインポートしますか？',
    C064: '選択している年休付与を削除しますか？',
    C065: '画面を初期化して新しい年休付与の入力を開始しますか？',
    C066: '日別シフト表を作成しますか？',
    C067: '申請してよろしいですか？',
    C068: '年休付与履歴を変更しますか？',
    C069: '年休付与データを削除しますか？',
    C070: 'の工数集計表をプレビューしますか？',
    C071: '画面を初期化して新しい作業の入力を開始しますか？',
    C072: '画面を初期化して新しいプロジェクトの入力を開始しますか？',
    C073: 'プロジェクトを削除しますか？',
    C074: '作業を削除しますか？',
    C075: '年休個別台帳を印刷しますか？',
    C076: '年休管理台帳を印刷しますか？',
    C077: '{0}から年休データをインポートしますか？',
    C078: '承認結果を取り消しますか？',
    C079: 'この就業規則を希望シフトとして設定いたしますか？<br/>(カレンダ設定が初期化されます)<br/>(「保存」実行するまでは更新は確定されません。)',
    C080: '選択している従業員を削除しますか？',
    C081: '{0}フィルターを保存せずに、検索を続けますか？',
    C082: '選択しているプロジェクトを削除しますか？',
    C083: '選択している作業を削除しますか？',
    C084: 'フェリカカードIDがデータベースに登録されていません',
    C085: '未確定データがありますが、エクスポート処理を続行しますか？',
    C086: '再集計してもよろしいですか？',
    C087: '2要素認証を無効にしますか？<br/>(ログイン時のワンタイムパスワードの入力が不要になります。)<br/>(セキュリティが低下する可能性があるためご注意ください。)',
    // Success message
    S001: '一括登録が終了しました。',
    S002: '事業所マスタがインポートされました。',
    S003: '組織マスタがインポートされました。',
    S004: '職種マスタがインポートされました。',
    S005: '役職マスタがインポートされました。',
    S006: '従業員マスタがインポートされました。',
    S007: '事由区分マスタがインポートされました。',
    S008: 'データの登録を完了しました',
    S009: '付与履歴の削除が完了しました',
    S010: '年休付与履歴の変更を完了しました',
    S011: 'データの削除を完了しました',
    S012: 'を完了しました',
    S013: '取消更新を完了しました',
    S014: '保存が正しく完了しました',
    S015: 'データの保存を完了しました',
    S016: '申請書の登録を完了しました',
    S017: '申請書を削除しました',
    S018: '確定が正しく完了しました',
    S019: '取消が正しく完了しました',
    S020: '勤務表の再集計を完了しました',
    S021: '勤務表の登録を完了しました',
    S022: 'カードの登録が完了しました',
    S023: '設定の保存を完了しました',
    S024: 'シフト表の登録を完了しました',
    S025: '就業規則の登録を完了しました',
    S026: '勤務日パターンの登録を完了しました',
    S027: '就業シフトの登録を完了しました',
    S028: '2要素認証の設定が完了しました',
    S029: '2要素認証の解除が完了しました',
    // api error
    E400: 'エラーが発生しました。もう一度お願いします。',
    E401: 'ログインセッションの有効期限が切れています。もう一度ログインしてください。',
    E403: 'この機能にアクセスする権限がありません。',
    E404: 'エラーが発生しました。もう一度お願いします。',
    E422: 'エラーが発生しました。もう一度お願いします。',
    E423: 'エラーが発生しました。もう一度お願いします。',
    E500: 'エラーが発生しました。もう一度お願いします。',
    E999: 'エラーが発生しました。もう一度お願いします。'
};

export const MSG_TITLE = {
    E999: 'エラー',
    C001: '一括登録確認'
};

export const MSG_TYPE = {
    CONFIRM: 1,
    WARNING: 2,
    SUCCESS: 3,
    ERROR: 4
};
