export default {
    事業所コード: '事業所コード',
    事業所名: '事業所名',
    フリガナ: 'フリガナ',
    略称: '略称',
    郵便番号: '郵便番号',
    住所: '住所',
    従業員コード: '従業員コード',
    担当者名: '担当者名',
    責任者名: '責任者名',
    電話番号: '電話番号',
    メールアドレス: 'メールアドレス'
};
