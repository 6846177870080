export default [
    {
        id: 1,
        name: 'マイページ',
        role: ['3', '2', '1'],
        childMenus: [
            {
                id: 'Gs0510v',
                secondId: 'Gm0020i',
                url: '/gs/gs0510v',
                name: 'タイムレコーダー',
                role: ['3', '2', '1']
            },
            {
                id: 'Gs0520i',
                url: '/gs/gs0520i',
                name: '勤怠実績・各種申請',
                role: ['3', '2', '1']
            },
            {
                id: 'Gs0193v',
                url: '/gs/gs0193v',
                name: '年次有給休暇の状況',
                isCheck利用者権限: true,
                role: ['3', '2', '1']
            },
            {
                id: 'Gs0710i',
                url: '/gs/gs0710i',
                name: 'アラート（本人）',
                role: ['3', '2', '1']
            }
        ]
    },
    {
        id: 2,
        name: '上司機能',
        role: ['3', '2'],
        childMenus: [
            {
                id: 'Gs0050v',
                url: '/gs/gs0050v',
                name: '承認待ち一覧',
                role: ['3', '2']
            },
            {
                id: 'Gs0711v',
                url: '/gs/gs0711v',
                name: 'アラート一覧',
                role: ['3', '2']
            },
            {
                id: 'Gs0060v',
                url: '/gs/gs0060v',
                name: '勤怠モニター',
                role: ['3', '2']
            },
            {
                id: 'Cm0100i',
                url: '/cm/cm0100i',
                name: 'カレンダー',
                from: 0,
                role: ['3', '2']
            },
            {
                id: 'Gs0360v',
                url: '/gs/gs0360v',
                name: '勤怠実績一覧',
                role: ['3', '2']
            },
            {
                id: 'Gr0420r',
                url: '/gr/gr0420r',
                name: '打刻漏れ一覧',
                role: ['3', '2']
            },
            {
                id: 'Gr0460i',
                url: '/gr/gr0460i',
                name: '年次有給休暇管理台帳',
                role: ['3', '2']
            },
            {
                id: 'Gs0610i',
                url: '/gs/gs0610i',
                name: 'シフト管理',
                role: ['3', '2']
            },
            {
                id: 'Gr0590r',
                url: '/gr/gr0590r',
                name: '実態乖離リスト',
                role: ['3', '2']
            }
        ]
    },
    {
        id: 3,
        name: '事業所情報',
        role: ['3'],
        childMenus: [
            {
                id: 'Cm0050i',
                url: '/cm/cm0050i',
                name: '事業所情報の設定',
                role: ['3']
            },
            {
                id: 'Cm0070i',
                url: '/cm/cm0070i',
                name: '職種情報の設定',
                role: ['3']
            },
            {
                id: 'Cm0061i',
                url: '/cm/cm0061i',
                name: '組織情報の設定',
                role: ['3']
            },
            {
                id: 'Cm0110i',
                url: '/cm/cm0110i',
                name: '役職情報の設定',
                role: ['3']
            }
        ]
    },
    {
        id: 4,
        name: '従業員情報',
        role: ['3'],
        childMenus: [
            {
                id: 'Cm0100i',
                url: '/cm/cm0100i?p=eyJmcm9tIjoxfQ2',
                name: '従業員情報の設定',
                from: 1,
                role: ['3']
            },
            {
                id: 'Gs0570i',
                url: '/gs/gs0570i',
                name: '従業員情報の一括設定',
                role: ['3']
            }
        ]
    },
    {
        id: 5,
        name: '就業ルール',
        role: ['3'],
        childMenus: [
            {
                id: 'Gs0540i',
                url: '/gs/gs0540i',
                name: '就業規則の設定',
                role: ['3']
            },
            {
                id: 'Gs0080i',
                url: '/gs/gs0080i',
                name: '36協定の設定',
                role: ['3']
            },
            {
                id: 'Gr0510i',
                url: '/gr/gr0510i',
                name: '36協定順守状況一覧',
                role: ['3']
            },
            {
                id: 'Gs0140i',
                url: '/gs/gs0140i',
                name: '年次有給休暇の設定',
                role: ['3']
            },
            {
                id: 'Gs0090i',
                url: '/gs/gs0090i',
                name: '事由区分の設定',
                role: ['3']
            }
        ]
    },
    {
        id: 6,
        name: 'ワークフロー',
        role: ['3'],
        childMenus: [
            {
                id: 'Gs0560i',
                url: '/gs/gs0560i',
                name: 'ワークフロー設定',
                role: ['3']
            }
        ]
    },
    {
        id: 7,
        name: 'アラート',
        role: ['3'],
        childMenus: [
            {
                id: 'Gs0110i',
                url: '/gs/gs0110i',
                name: 'アラート設定',
                role: ['3']
            }
        ]
    },
    {
        id: 8,
        name: '帳票印刷・データ交換',
        role: ['3'],
        childMenus: [
            {
                id: 'Cm9020i',
                url: '/cm/cm9020i',
                name: 'マスタ送受信',
                role: ['3']
            },
            {
                id: 'Gs9061i',
                url: '/gs/gs9061i',
                name: 'タイムレコード送受信',
                role: ['3']
            },
            {
                id: 'Gr0450r',
                url: '/gr/gr0450r',
                name: '集計データダウンロード',
                role: ['3']
            },
            {
                id: 'Gr0390r',
                url: '/gr/gr0390r',
                name: '就業日報',
                role: ['3']
            },
            {
                id: 'Gr0440r',
                url: '/gr/gr0440r',
                name: '就業月報',
                role: ['3']
            },
            {
                id: 'Gr0500r',
                url: '/gr/gr0500r',
                name: '就業年報',
                role: ['3']
            },
            {
                id: 'Gr0400r',
                url: '/gr/gr0400r',
                name: '集計レポート(月次推移表)',
                role: ['3']
            }
        ]
    },
    {
        id: 9,
        name: '工数管理',
        role: ['3'],
        childMenus: [
            {
                id: 'Pj0010i',
                url: '/pj/pj0010i',
                isCheck利用者権限: true,
                name: 'プロジェクトの管理',
                role: ['3']
            },
            {
                id: 'Pj0040i',
                url: '/pj/pj0040i',
                isCheck利用者権限: true,
                name: 'プロジェクトの実績',
                role: ['3']
            }
        ]
    }
];

export const MobileMenu = [
    {
        id: 'Gm0020i',
        secondId: 'Gs0510v',
        url: '/gm/gm0020i',
        name: 'タイムレコーダー',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0030v',
        url: '/gm/gm0030v',
        name: '勤怠実績',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0040i',
        url: '/gm/gm0040i',
        name: '申請一覧・各種申請',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0050v',
        url: '/gm/gm0050v',
        name: '年次有給休暇の状況',
        isCheck利用者権限: true,
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0060v',
        url: '/gm/gm0060v',
        name: 'アラート（本人）',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0070i',
        url: '/gm/gm0070i',
        name: '承認待ち一覧',
        role: ['3', '2']
    },
    {
        id: 'Gm0080v',
        url: '/gm/gm0080v',
        name: 'アラート一覧',
        role: ['3', '2']
    },
    {
        id: 'Gm0090v',
        url: '/gm/gm0090v',
        name: '勤怠モニター',
        role: ['3', '2']
    }
];

export const MobileFooter = [
    {
        id: 'Gm0020i',
        secondId: 'Gs0510v',
        url: '/gm/gm0020i',
        name: 'ﾀｲﾑﾚｺｰﾀﾞｰ',
        icon: 'fa-solid fa-clock',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0030v',
        url: '/gm/gm0030v',
        name: '勤怠実績',
        icon: 'fa-solid fa-calendar-days',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0060v',
        url: '/gm/gm0060v',
        name: 'アラート(本人)',
        icon: 'fa-solid fa-bell',
        role: ['3', '2', '1']
    },
    {
        id: 'Gm0070i',
        url: '/gm/gm0070i',
        name: '承認一覧',
        icon: 'fa-solid fa-list-ul',
        role: ['3', '2']
    },
    {
        id: 'Gm0080v',
        url: '/gm/gm0080v',
        name: 'アラート一覧',
        icon: 'fa-regular fa-bell',
        role: ['3', '2']
    }
];

export const NeedMoreCheck = {
    3: ['Gs0193v', 'Gm0050v', 'Pj0010i', 'Pj0040i'],
    2: [
        'Gs0193v',
        'Gs0050v',
        'Gs0711v',
        'Gs0060v',
        'Cm0100i',
        'Gs0360v',
        'Gr0420r',
        'Gr0460i',
        'Gs0610i',
        'Gr0590r',
        'Gm0050v',
        'Gm0070i',
        'Gm0080v',
        'Gm0090v'
    ],
    1: ['Gs0193v', 'Gm0050v']
};
