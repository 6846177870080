export default {
    従業員コード: '従業員コード',
    権限: '権限',
    氏名: '氏名',
    メールアドレス: 'メールアドレス',
    パスワード: '現在のパスワード',
    新しいパスワード: '新しいパスワード',
    確認用パスワード: '確認用パスワード',
    パスワードを表示: 'パスワードを表示'
};
