import axios from 'axios';
import _ from 'lodash-uuid';
import messages, { MSG_TYPE, MSG_TITLE } from './messages';
import store from '@/store';
import Router from '@/router';

const baseUrl =
    (process.env.VUE_APP_BASE_DOMAIN ?? '') +
    (process.env.VUE_APP_BASE_PATH ?? '/api/v1');
const apiKey = process.env.VUE_APP_API_KEY;
const timeout = process.env.VUE_APP_API_TIMEOUT ?? 60000;

var repository = axios.create({
    baseURL: baseUrl,
    timeout: timeout,
    headers: {
        'x-api-key': apiKey
    }
});

repository.interceptors.request.use(
    function (config) {
        config.headers['X-Request-Id'] = _.uuid();
        config.headers['X-Switch-User'] = sessionStorage.getItem('switchUser');
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const isNoLoading = store.state.app.isNoLoading;
        if (config.url != '/common/app-state') {
            store.commit('app/hideHeaderError');
        }
        store.commit('app/showLoading');
        if (!isNoLoading) {
            store.commit('app/increaseCountLoading');
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

repository.interceptors.response.use(
    function (response) {
        const countLoading = store.state.app.countLoading;
        if (countLoading - 1 <= 0) {
            store.commit('app/hideLoading');
        }
        store.commit('app/decreaseCountLoading');
        const { data } = response;
        const appData = store._state.data.app;
        if (data.Code !== 200) {
            if (data.Code === 422) {
                try {
                    for (const key in data.DataErrors) {
                        if (
                            Object.prototype.hasOwnProperty.call(
                                data.DataErrors,
                                key
                            )
                        ) {
                            try {
                                // eslint-disable-next-line no-undef
                                $(`#${key}, [name="${key}"]`).ItemError(
                                    messages[data.DataErrors[key][0]]
                                );
                                // eslint-disable-next-line prettier/prettier, no-empty
                            } catch (ex) {}
                        }
                    }
                    // eslint-disable-next-line prettier/prettier, no-empty
                } catch (ex) {}
            } else if (data.Code === 423 || data.Code === 999) {
                store.commit('app/showModalMessage', {
                    type: MSG_TYPE.ERROR,
                    content: data.Message,
                    callback: () => {
                        if (appData.onApiError) {
                            appData.onApiError();
                        }
                    }
                });
            } else {
                store.commit('app/showModalMessage', {
                    type: MSG_TYPE.ERROR,
                    title: MSG_TITLE.E999,
                    // eslint-disable-next-line prettier/prettier
                    content: data.Code === 401 ? messages.E401 : data.Code === 403 ? messages.E403 : messages.E999,
                    callback: () => {
                        if (data.Code === 401) {
                            sessionStorage.setItem(
                                'beforeUrl',
                                window.location.pathname
                            );
                            sessionStorage.removeItem('token');
                            sessionStorage.removeItem('tokenTimeout');
                            Router.push(appData.loginScreen);
                        }
                    }
                });
            }
            store.commit('app/hideForceLoading');
        }
        return response;
    },
    function (error) {
        const countLoading = store.state.app.countLoading;
        if (countLoading - 1 <= 0) {
            store.commit('app/hideLoading');
            store.commit('app/hideForceLoading');
        }
        store.commit('app/decreaseCountLoading');
        store.commit('app/showModalMessage', {
            type: MSG_TYPE.ERROR,
            content: messages.E999
        });
        return Promise.reject(error);
    }
);

export default repository;
