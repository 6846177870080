export default {
    契約会社コード: '契約会社コード',
    企業コード: '企業コード',
    登録属性: '登録属性',
    利用期間: '利用期間',
    利用期間始: '利用期間始',
    利用期間終: '利用期間終',
    会社名: '会社名',
    フリガナ: 'フリガナ',
    会社名フリガナ: '会社名フリガナ',
    略称: '略称',
    会社略称: '会社略称',
    郵便番号: '郵便番号',
    住所: '住所',
    従業員コード: '従業員コード',
    担当者従業員コード: '担当者従業員コード',
    担当者名: '担当者名',
    担当者フリガナ: '担当者フリガナ',
    責任者従業員コード: '責任者従業員コード',
    責任者名: '責任者名',
    責任者フリガナ: '責任者フリガナ',
    電話番号: '電話番号',
    メールアドレス: 'メールアドレス',
    会社メールアドレス: '会社メールアドレス',
    利用会社と同じ: '利用会社と同じ',
    請求先会社名: '請求先会社名',
    請求先フリガナ: '請求先フリガナ',
    請求先略称: '請求先略称',
    請求宛先郵便番号: '請求宛先郵便番号',
    請求先住所: '請求先住所',
    請求先担当者従業員コード: '請求先担当者従業員コード',
    請求先担当者名: '請求先担当者名',
    請求先担当者フリガナ: '請求先担当者フリガナ',
    請求先責任者従業員コード: '請求先責任者従業員コード',
    請求先責任者名: '請求先責任者名',
    請求先責任者フリガナ: '請求先責任者フリガナ',
    請求宛先電話番号: '請求宛先電話番号',
    請求先メールアドレス: '請求先メールアドレス',
    スタンダードプラン: 'スタンダードプラン',
    工数管理オプション: '工数管理オプション'
};
