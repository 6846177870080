/* eslint-disable prettier/prettier */
import repository from '@/utils/repository';
import store from '@/store';
import helpers from '@/utils/helpers';
import messages, { MSG_TYPE } from '@/utils/messages';

const resource = '/common';
const exportTimeout = helpers.getNumber(process.env.VUE_APP_EXPORT_TIMEOUT ?? 120) * 1000;
const exportInterval = helpers.getNumber(process.env.VUE_APP_INTERVAL ?? 500);
const fileDomain = process.env.VUE_APP_FILE_DOMAIN ?? '/export';

export default {
    getAppState: (data) => {
        return repository.get(`${resource}/app-state`, {
            params: data
        });
    },
    getCurrentTime: () => {
        return repository.get(`${resource}/current-time`);
    },
    exportPDF: (body, callback, confirmBeforeDownload) => {
        store.commit('app/showForceLoading');
        repository.post(`${resource}/create-export`, body).then((res) => {
            const { data } = res;
            const res1 = data;
            if (
                data.Code === 200 &&
                !helpers.isNullOrEmpty(data.Data.returnno)
            ) {
                const checkDownloadFile = () => {
                    store.commit('app/showForceLoading');
                    let count = 0;
                    let requesting = false;
                    const loop = setInterval(() => {
                        if (!requesting) {
                            requesting = true;
                            repository
                                .get(`${resource}/get-file-export`, {
                                    params: {
                                        p002: data.Data.returnno
                                    }
                                })
                                .then((res) => {
                                    const { data } = res;
                                    if (
                                        data.Code === 200 &&
                                    data.Data.outputstatus != undefined &&
                                    data.Data.outputstatus != null
                                    ) {
                                        clearInterval(loop);
                                        store.commit('app/hideForceLoading');
                                        if (data.Data.outputstatus == 1) {
                                            setTimeout(() => {
                                                const arrUrls =
                                                data.Data.outputurl.split('/');
                                                const domain =
                                                fileDomain +
                                                (data.Data.outputclass == 'R'
                                                    ? ('/' + arrUrls[
                                                        arrUrls.length - 1
                                                    ])
                                                    : '');
                                                const anchor =
                                                document.createElement('a');
                                                anchor.href = `${domain}?url=${
                                                    data.Data.outputurl
                                                }&action=${
                                                    data.Data.outputclass
                                                }&token=${sessionStorage.getItem(
                                                    'token'
                                                )}`;
                                                anchor.target =
                                                data.Data.outputclass == 'R'
                                                    ? '_blank'
                                                    : '';
                                                setTimeout(() => {
                                                    anchor.click();
                                                }, 100);
                                            }, 100);
                                        } else {
                                            store.commit('app/showModalMessage', {
                                                type: MSG_TYPE.ERROR,
                                                content: data.Data.outputmessage
                                            });
                                        }
                                        if (typeof callback == 'function') {
                                            callback(res1, data);
                                        }
                                    }
                                    if (
                                        count >= exportTimeout ||
                                    data.Code !== 200
                                    ) {
                                        clearInterval(loop);
                                        if (count >= exportTimeout) {
                                            store.commit('app/hideForceLoading');
                                            store.commit('app/showModalMessage', {
                                                type: MSG_TYPE.ERROR,
                                                content: messages.E040
                                            });
                                        }
                                    }
                                    requesting = false;
                                })
                                .catch(() => {
                                    requesting = false;
                                    clearInterval(loop);
                                });
                        }
                        count += exportInterval;
                    }, exportInterval);
                }
                if (confirmBeforeDownload) {
                    confirmBeforeDownload(data.Data.returnno, checkDownloadFile);
                } else {
                    checkDownloadFile();
                }
            } else {
                store.commit('app/hideForceLoading');
            }
        });
    }
};
