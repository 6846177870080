import cm0011i from '@/views/cm/cm0011i/label';
import cm0020i from '@/views/cm/cm0020i/label';
import cm0050i from '@/views/cm/cm0050i/label';
import gs0194v from '@/views/gs/gs0194v/label';
import gs0090i from '@/views/gs/gs0090i/label';
import cm0070i from '@/views/cm/cm0070i/label';
import cm0061i from '@/views/cm/cm0061i/label';
import gs0570i from '@/views/gs/gs0570i/label';
import gs0080i from '@/views/gs/gs0080i/label';

export default {
    cm0011i: cm0011i,
    cm0020i: cm0020i,
    gs0194v: gs0194v,
    gs0090i: gs0090i,
    cm0050i: cm0050i,
    cm0070i: cm0070i,
    cm0061i: cm0061i,
    gs0570i: gs0570i,
    gs0080i: gs0080i
};
